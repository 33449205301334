import { get, map } from 'lodash';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import DataTable from '../../../core/components/DataTable';
import DateRangeSelector from '../../../core/components/DateRangeSelector';
import PageActions from '../../../core/components/PageActions';
import {
  addPermissionRefs,
  findPermissionRef,
  viewPermissionRefs,
} from '../../../core/components/UserGroupPermissions';
import { PermissionKeys } from '../../../core/components/UserPermissions';
import { fetchPurchaseOrders as fetchPurchaseOrdersAction } from '../actions';
import { PurchaseOrder } from '../models';
import CategoryFilter from './CategoryFilter';
import StatusButton from './StatusButton';
import StatusFilter from './StatusFilter';
import VendorFilter from './VendorFilter';
import moment from 'moment';

export const PurchaseOrderTable = ({
  purchaseOrders,
  pageInfo,
  fromDate,
  signal,
  showDialog,
  untilDate,
  setFromDate,
  setUntilDate,
  fetchPurchaseOrders,
  receivePurchaseOrder,
  unreceivePurchaseOrder,
  categories,
  vendors,
  permissions,
  userGroup,
}) => {
  const [currentFilters, setCurrentFilters] = useState({
    currentCategory: 'All',
    currentVendor: 'All',
    currentStatus: 'All',
  });
  const [sortQuery, setSortQuery] = useState(null);
  const params = useParams();
  const intl = useIntl();
  const getQueryParams = (page = 0) => {
    const [from, until] = [fromDate.format('YYYY-MM-DD'), untilDate.format('YYYY-MM-DD')];

    return { page, from, until, signal, currentFilters, sortQuery };
  };

  const handleFilterChanges = () => {
    if (permissions[PermissionKeys.viewPurchaseOrdersTab]) {
      fetchPurchaseOrders(getQueryParams());
    }
  };

  useEffect(handleFilterChanges, [currentFilters, sortQuery]);

  const getTableData = () => {
    let option = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
    return map(purchaseOrders, (pos) => {
      return {
        _id: pos.id,
        poNumber: pos.orderNumber,
        location: pos.siteName,
        _memo: pos.memo,
        category: pos.categoryName,
        vendor: pos.vendorName,
        _trackingNumber: pos.trackingNumber,
        total: `$${Number(pos.total).toLocaleString(undefined, option)}`,
        _receivedAt: pos.receivedAt,
      };
    });
  };

  const triggerFetchPurchaseOrders = (sortedQuery) => {
    if (get(sortedQuery, 'key', false)) {
      setSortQuery(sortedQuery);
    }
  };

  const getPageActions = () => {
    const queryParams = getQueryParams();

    const callback = () => {
      return fetchPurchaseOrdersAction(queryParams);
    };

    return [
      {
        dialogType: 'ADD_PURCHASE_ORDER',
        permissionKey: addPermissionRefs.expense,
        text: intl.formatMessage({ id: 'addPurchaseOrder' }),
        dialogProps: { isNew: true, callback },
      },
      {
        dialogType: 'ADD_VENDOR',
        permissionKey: addPermissionRefs.expense_vendor,
        text: intl.formatMessage({ id: 'addVendor' }),
        permissionKey: addPermissionRefs.expense,
      },
    ];
  };

  const getPurchaseOrdersPage = (page) => {
    if (findPermissionRef(userGroup.permissions, viewPermissionRefs.expense)) {
      fetchPurchaseOrders(getQueryParams(page));
    }
  };

  const initializeDatatable = () => {
    const headers = [
      { name: intl.formatMessage({ id: 'poNumber' }), databaseProperty: 'id' },
      { name: intl.formatMessage({ id: 'location' }), databaseProperty: 'siteName' },
      {
        name: intl.formatMessage({ id: 'memo' }),
        cell: {
          component: (d) => <p className="truncated">{d.rowData._memo}</p>,
        },
      },
      {
        name: intl.formatMessage({ id: 'category' }),
        databaseProperty: 'categoryName',
      },
      {
        name: intl.formatMessage({ id: 'vendor' }),
        databaseProperty: 'vendorName',
      },
      {
        name: intl.formatMessage({ id: 'trackingNumber' }),
        cell: {
          component: (p) => (
            <a
              href={`https://www.google.com/search?q=${p.rowData._trackingNumber}`}
              target="_blank"
            >
              {p.rowData._trackingNumber}
            </a>
          ),
        },
      },
      { name: intl.formatMessage({ id: 'total' }), databaseProperty: 'total' },
      {
        name: intl.formatMessage({ id: 'status' }),
        databaseProperty: 'receivedAt',
        cell: {
          component: StatusButton,
          mixIns: {
            receive: receivePurchaseOrder,
            unreceive: unreceivePurchaseOrder,
            showDialogs: showDialog,
            disabled: !permissions[PermissionKeys.receivePurchaseOrders],
          },
        },
      },
    ];

    const paginationInfo = {
      ...pageInfo,
      goToPage: getPurchaseOrdersPage,
    };

    return {
      data: getTableData(),
      headers,
      paginationInfo,
      scrolling: true,
      fetchSortedData: triggerFetchPurchaseOrders,
    };
  };

  const navigationInfo = {
    navigationProperty: '_id',
    path: `/${params.site}/expenses/:id`,
  };

  return (
    <div className="purchase-orders">
      <div className="table-buttons">
        <div className="as-of-date-container">
          <DateRangeSelector
            dates={[fromDate, untilDate]}
            onSubmit={(dates) => {
              setFromDate(dates.from);
              setUntilDate(dates.until);
            }}
          />
        </div>
        <PageActions actionFunction={showDialog} actions={getPageActions()} />
      </div>
      <div className="table-filters">
        <StatusFilter currentFilter={currentFilters} setFilter={setCurrentFilters} />
        <CategoryFilter
          currentFilter={currentFilters}
          setFilter={setCurrentFilters}
          categories={categories}
        />
        <VendorFilter
          currentFilter={currentFilters}
          setFilter={setCurrentFilters}
          vendors={vendors}
        />
      </div>
      <DataTable navigationInfo={navigationInfo} {...initializeDatatable()} />
    </div>
  );
};

PurchaseOrderTable.propTypes = {
  pageInfo: PropTypes.shape({}).isRequired,
  permissions: PropTypes.shape({}),
  userGroup: PropTypes.shape({}),
  showDialog: PropTypes.func.isRequired,
  purchaseOrders: PropTypes.arrayOf(PropTypes.shape(PurchaseOrder)),
  fetchPurchaseOrders: PropTypes.func.isRequired,
  receivePurchaseOrder: PropTypes.func.isRequired,
  unreceivePurchaseOrder: PropTypes.func.isRequired,
  signal: PropTypes.shape({}),
  fromDate: PropTypes.shape({ format: PropTypes.func }),
  untilDate: PropTypes.shape({ format: PropTypes.func }),
  setFromDate: PropTypes.func.isRequired,
  setUntilDate: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({})),
  vendors: PropTypes.arrayOf(PropTypes.shape({})),
};

export default PurchaseOrderTable;
