import { PropTypes } from 'prop-types';
import React, { useEffect } from 'react';
import useAbortController from '../../../core/abortController';
import {
  findPermissionRef,
  viewPermissionRefs,
} from '../../../core/components/UserGroupPermissions';
import PurchaseOrderTable from './PurchaseOrderTable';
import moment from 'moment';

export const PurchaseOrderDataGetter = (props) => {
  const [from, until] = [props.fromDate.format('YYYY-MM-DD'), props.untilDate.format('YYYY-MM-DD')];
  const abortController = useAbortController([from, until]);
  const threeMonthsAgo = moment(from).subtract(3, 'months').format('YYYY-MM-DD');

  const getPurchaseOrders = () => {
    if (findPermissionRef(props.userGroup.permissions, viewPermissionRefs.expense)) {
      props.fetchPurchaseOrders({
        threeMonthsAgo,
        until,
        signal: abortController.signal,
      });
    }
  };
  useEffect(() => {
    getPurchaseOrders();
    props.fetchPurchaseOrderVendors();
    props.fetchPurchaseOrderCategories();
  }, [abortController]);

  return <PurchaseOrderTable {...props} signal={abortController.signal} />;
};
/* eslint-disable */
PurchaseOrderDataGetter.propTypes = {
  fromDate: PropTypes.shape({ format: PropTypes.func }),
  untilDate: PropTypes.shape({ format: PropTypes.func }),
  fetchPurchaseOrderCategories: PropTypes.func.isRequired,
  fetchPurchaseOrderVendors: PropTypes.func.isRequired,
  fetchPurchaseOrders: PropTypes.func.isRequired,
  deletePurchaseOrder: PropTypes.func.isRequired,
  fetchPurchaseOrder: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      site: PropTypes.string,
    }),
  }),
};

export default PurchaseOrderDataGetter;
