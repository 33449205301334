import { Controller } from 'react-hook-form';
import moment from 'moment';
import DateTimePicker from '../../../core/components/DateTimePicker';
import { useEffect, useState } from 'react';

interface VendingSalesRowProps {
  site: any;
  form: any;
  index: number;
  validCallback: any;
  intl: any;
}

const VendingSalesRow = ({ site, form, index, validCallback, intl }: VendingSalesRowProps) => {
  const [valid, setValid] = useState(false);
  const amountSelected = form.watch(`sales[${index}].amount`);
  const isValidField = (fieldValue: any) => {
    return fieldValue && fieldValue != '';
  };

  const getPreviousSaturday = () => {
    const today = moment();
    // Adjust to the previous Saturday
    const previousSaturday = today.day(today.day() === 6 ? -7 : -1);
    return previousSaturday.format('ww/YYYY');
  };
  useEffect(() => {
    form.setValue(`sales[${index}].statementPeriod`, getPreviousSaturday());
  }, []);

  useEffect(() => {
    form.setValue(
      `sales[${index}].statementPeriod`,
      moment().subtract(1, 'week').format('ww/YYYY')
    );
  }, []);
  useEffect(() => {
    if (isValidField(amountSelected)) {
      setValid(true);
      validCallback(site.id, true);
    } else {
      setValid(false);
      validCallback(site.id, false);
    }
  }, [amountSelected]);
  return (
    <div className="form-row">
      <p>
        <i className={`uil uil-check-circle form-checkmark${valid ? ' visible' : ''}`}></i>
        {site.externalId && <>{site.externalId} - </>}
        {site.name}
      </p>
      <div>
        <div className="form-group">
          <label>{intl.formatMessage({ id: 'uploadWeek' })}</label>
          <input
            {...form.register(`sales[${index}].statementPeriod`, {
              valueAsNumber: false,
            })}
            className="form-control"
            disabled={true}
            type="text"
          />
        </div>
        <div className="form-group vending-totals">
          <label>{intl.formatMessage({ id: 'enterTotals' })}</label>
          <input
            {...form.register(`sales[${index}].amount`, {
              valueAsNumber: true,
              min: 0,
            })}
            className="form-control"
            type="number"
            step="0.01"
          />
        </div>
      </div>
      <input
        {...form.register(`sales[${index}].siteId`, {
          valueAsNumber: true,
        })}
        defaultValue={site.id}
        className="form-control hidden"
      />
    </div>
  );
};

export default VendingSalesRow;
